import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";

export default {
  name: 'notifications.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        title: '',
        description: '',
        due_date: '',
      },
      isShowDatePopup: false,
      isEditing: false,
      validationErrors: {}
    }
  },
  components: {},
  validations: {
    payload: {
      title: {
        required,
        maxLength: maxLength(20),
      },
      description: {
        required,
        maxLength: maxLength(80),
      },
      due_date: {
        required
      },
    }
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      isNotificationCreating: 'notifications/isNotificationCreating',
    }),
    ...mapGetters([
      'currentLanguage',
    ]),
    titleErrors() {
      let error = [];
      if (!this.$v.payload.title.$dirty) {
        return error;
      }
      if (!this.$v.payload.title.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      if (this.validationErrors.title) {
        this.validationErrors.title.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    descriptionErrors() {
      let error = [];
      if (!this.$v.payload.description.$dirty) {
        return error;
      }
      if (!this.$v.payload.description.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.description.maxLength) {
        error.push(this.$t('validationMax',{count: 80}));
      }
      if (this.validationErrors.description) {
        this.validationErrors.description.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    dateErrors() {
      let error = [];
      if (!this.$v.payload.due_date.$dirty) {
        return error;
      }
      if (!this.$v.payload.due_date.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.due_date) {
        this.validationErrors.due_date.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    formattedDate() {
      return this.payload.due_date ? moment(this.payload.due_date).format('DD/MM/YYYY') : null;
    },
  },
  methods: {
    ...mapActions({
      createNotifications: 'notifications/NOTIFICATIONS_REQUEST_CREATE'
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createNotifications(this.payload).then(() => {
          this.$toasted.success(this.$t('notificationsCreated'));
          this.$router.push({name: 'notifications.list'}).catch(()=> {console.log()})
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    },
  }
}
